<!-- App.vue -->

<template>
  <VApp>
    <AppLayout />
  </VApp>
</template>

<script>
import AppLayout from '@/components/AppLayout.vue' // Adjust the path accordingly

export default {
  components: {
    AppLayout,
  },
}
</script>
<style>
@import '@/styles/main.css';
html,
body {
  font-family: 'Rubik', sans-serif;
  background: #18181b;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
