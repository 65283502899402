<template>
  <div>
    <VRow class="justify-center" dense v-for="article in paginatedArticles" :key="article.id">
      <VCol xs="12" sm="6" v-if="!this.loading">
        <ArticleCard :article="article" :deleteArticle="deleteArticle" />
        <VDivider color="grey" class="w-100 my-10" />
      </VCol>
      <VCol xs="12" sm="6" v-else>
        <LoaderCircle loaderType="articlecard" class="my-10" />
      </VCol>
    </VRow>

    <VPagination v-if="!this.loading" class="text-grey" :length="totalPages" v-model="currentPage"></VPagination>
  </div>
</template>

<script>
import ArticleCard from '@/components/ArticleCard.vue'
import LoaderCircle from '@/components/LoaderCircle.vue'
import { supabase } from '@/lib/supabaseClient'

export default {
  components: { ArticleCard, LoaderCircle },
  data() {
    return {
      articles: [],
      loading: true,
      itemsPerPage: 3,
      currentPage: 1,
      numberOfPages: 0,
    }
  },
  created() {
    this.fetchArticles()
  },
  methods: {
    async fetchArticles() {
      const { data } = await supabase.from('articles').select()
      this.articles = data.sort((a, b) => new Date(b.date) - new Date(a.date))
      this.articles = this.articles.filter((article) => !article.disabled)
      this.loading = false
    },
    async deleteArticle(id) {
      const { data, error } = await supabase.from('articles').delete().match({ id: id })

      if (error) {
        console.error('Error deleting article:', error.message)
        this.$toast.open({
          message: 'Article could not delete!',
          type: 'error',
        })
      } else {
        console.log('Article deleted successfully:', data)
        this.articles = this.articles.filter((article) => article.id !== id)
        this.$toast.open({
          message: 'Article succesfully deleted!',
          type: 'success',
        })
      }
    },
  },
  watch: {
    currentPage(newPage) {
      this.currentPage = newPage
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.articles.length / this.itemsPerPage)
    },
    paginatedArticles() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage
      return this.articles.slice(startIndex, endIndex)
    },
  },
}
</script>

<style></style>
