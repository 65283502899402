import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import ToastPlugin from 'vue-toast-notification'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import 'vue-toast-notification/dist/theme-bootstrap.css'
loadFonts()

createApp(App)
  .use(vuetify)
  .use(router)
  .use(ToastPlugin, {
    position: 'top',
  })
  .component('QuillEditor', QuillEditor) // Register the QuillEditor component
  .mount('#app')
