<template>
  <VCard class="article-card">
    <VRow class="flex-no-wrap">
      <VCol v-if="isAdmin" class="d-flex align-center justify-end" cols="12">
        <VMenu>
          <template v-slot:activator="{ props }">
            <VIcon icon="mdi-dots-vertical" v-bind="props"></VIcon>
          </template>

          <VList class="bg-white">
            <VListItem class="text-black">
              <VListItemTitle class="cursor-pointer" @click="deleteArticle(article.id)">
                <!-- <VIcon class="delete me-3" color="warning" @click="deleteArticle(article.id)">mdi-delete</VIcon> -->
                Sil
              </VListItemTitle>
            </VListItem>
            <VListItem>
              <VListItemTitle>
                <RouterLink :to="`/updatearticle/${article.id}`" class="text-black text-decoration-none pa-0 ma-0">
                  <!-- <VIcon class="update" color="success">mdi-pencil</VIcon> -->
                  Güncelle
                </RouterLink>
              </VListItemTitle>
            </VListItem>
          </VList>
        </VMenu>
      </VCol>
      <VCol sm="12">
        <RouterLink :to="`/article/${article.id}`" class="text-decoration-none">
          <VCardTitle class="text-h6 pa-0 white-text"> {{ article.title }} </VCardTitle>

          <VCardSubtitle v-if="article.summary" class="nowrap pa-0 my-3" align="start">{{
            article.summary
          }}</VCardSubtitle>
        </RouterLink>
      </VCol>

      <VCol class="d-flex flex-column flex-sm-row align-sm-center justify-space-between" cols="12" v-if="labels">
        <div>
          <VChip color="grey" label v-for="(label, i) in labels" :key="i" class="me-2 mb-2">
            {{ label }}
          </VChip>
        </div>
      </VCol>

      <VCol class="d-flex flex-column flex-sm-row align-start align-sm-center justify-space-between ga-4 ga-sm-0">
        <small class="d-flex align-center grey-text opacity-50" v-if="article.date">
          <VIcon class="me-2">mdi-calendar</VIcon>
          {{ getConvertedDate(article.date) }}
          <span class="mx-2">·</span>
          {{articleViewCount}} GÖRÜNTÜLEME
          <span class="mx-2">·</span>
          {{ readingTime }} MIN READ
        </small>

        <div class="d-flex ml-auto"  v-if="articleCommentsCount || article.clapCount">
          <div class="d-flex align-center" color="red">
            <VIcon size="xs" color="red" class="me-1">mdi-heart</VIcon>
            <span>{{ article.clapCount }}</span>
          </div>
          <div class="d-flex align-center ms-3" color="blue">
            <VIcon size="xs" color="grey" class="me-1">mdi-comment</VIcon>
            <span>{{ articleCommentsCount }}</span>
          </div>
        </div>
      </VCol>
    </VRow>
  </VCard>
</template>

<script>
import { convertDate } from '@/utils/convertDate'
import { calculateReadingTime } from '@/utils/calculateReadingTime'
import { supabase } from '../lib/supabaseClient'
export default {
  props: {
    article: {
      type: Object, // Assuming article is an object
      required: true, // You can change this based on your requirements
    },
  },
  data: () => ({
    labels: [],
    articleCommentsCount: 0,
    articleViewCount: 0,
    readingTime: 0
  }),
  created() {
    if (this.article.labels) {
      this.labels = this.article.labels.split(',')
    }
    this.fetchArticleComments()
    this.fetchArticleViewCount()
  },
  methods: {
    getConvertedDate(date) {
      return convertDate(date)
    },
    async fetchArticleComments() {
      const { data } = await supabase.from('comments').select().filter('id', 'eq', this.article.id)
      this.articleCommentsCount = data.length
    },
    async fetchArticleViewCount() {
      const { data } = await supabase.from('articles').select().filter('id', 'eq', this.article.id)
      console.warn('data', data)
      this.articleViewCount = data[0].viewCount
      this.readingTime = calculateReadingTime(data[0].content)
    },
  },
  computed: {
    isAdmin() {
      return process.env.VUE_APP_ADMIN === 'true'
    },
  },
}
</script>

<style>
.v-card-title {
  white-space: unset;
}

.article-card {
  color: #d4d4d8;
  background: #18181b;
  box-shadow: unset;
}
</style>
