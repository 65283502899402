<template>
  <footer class="d-flex flex-column pa-0 text-white">
    <VRow class="border-grey">
      <div class="bg-dark d-flex align-center justify-center w-100 px-4">
        <VBtn @click="openLink(icon.link)" v-for="icon in icons" :key="icon.icon" class="mx-4 d-flex" :icon="icon.icon" variant="plain" size="small"> </VBtn>
      </div>

      <div class="px-4 py-2 bg-dark text-center w-100">
        {{ new Date().getFullYear() }} — <strong>&copy;ecsabanci</strong>
      </div>
    </VRow>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      icons: [
        {icon: 'mdi-twitter', link: "https://twitter.com/ecsabanci"},
        {icon: 'mdi-linkedin', link: "https://linkedin.com/in/ecsabanci"},
        {icon: 'mdi-instagram', link: "https://www.instagram.com/ecsabanci"}
      ],
    }
  },
  methods: {
    openLink(link) {
      window.open(link, '_blank')
    },
  },
}
</script>
<style>
.v-application__wrap {
  min-height: auto;
}
</style>
