<template>
  <VSheet class="mx-auto">
    <VForm class="border-0" validate-on="submit lazy" @submit.prevent="submit">
      <VTextField class="mb-5" v-model="article.title" label="Başlık" :rules="rules" hide-details="auto"></VTextField>
      <QuillEditor ref="quill" contentType="html" v-model:content="article.content" theme="snow" toolbar="full" />

      <VTextField class="mt-5" v-model="article.summary" label="Özet"></VTextField>
      <VTextField class="mt-5" v-model="article.labels" label="Etiketler(virgül ile ayırın)"></VTextField>
      <VTextField class="mt-5" v-model="article.image" accept="image/*" label="Fotoğraf"></VTextField>

      <v-img :src="article.image" v-if="article.image" max-width="500"></v-img>
      <VSpacer />
      <VBtn :loading="loading" type="submit" block class="mt-2 orange rounded-0" text="GÖNDER" />
    </VForm>
  </VSheet>
</template>
<script>
import { QuillEditor } from '@vueup/vue-quill'
import { supabase } from '@/lib/supabaseClient'
export default {
  components: {
    QuillEditor,
  },
  data() {
    return {
      isUpdatePage: false,
      article: {
        title: '',
        content: '',
        summary: '',
        image: null,
        labels: '',
      },
      imagePreview: null,
      loading: false,
              chips: [],
        items: [],
      rules: [(value) => !!value || 'Required.', (value) => (value && value.length >= 3) || 'Min 3 characters'],
    }
  },
  created() {
    this.isUpdatePage = this.$route.path.indexOf('update') === 1
    this.isUpdatePage && this.fetchArticle()
  },
  methods: {
    async submit() {
      // Ensure title and content are present
      if (!this.article.title || !this.article.content) {
        this.$toast.open({
          message: 'Lütfen göndermeden önce başlığı ve içeriği doldurun!',
          type: 'error',
        })
        return // Stop further execution if validation fails
      }

      this.article.date = new Date()
      // Add or update article in the database
      const { data, error } = this.isUpdatePage
        ? await supabase.from('articles').update(this.article).match({ id: this.$route.params.id })
        : await supabase.from('articles').insert(this.article)

      // Show toast based on the result
      this.$toast.open({
        message: error
          ? 'Hata oluştu. Lütfen tekrar deneyin!'
          : this.isUpdatePage
          ? 'Başarıyla güncellendi!'
          : 'Başarıyla Eklendi!',
        type: error ? 'error' : 'success',
      })

      if (!error) {
        console.log('Başarıyla eklendi:', data)
      }
    },
    clearFormFields() {
      this.article = {
        title: '',
        content: '',
        summary: '',
        image: null,
      }
      this.imagePreview = null
    },
    async fetchArticle() {
      const { data } = await supabase.from('articles').select().eq('id', this.$route.params.id)
      this.article = data[0]
      this.$refs.quill.setContents(this.article.content)
      this.loading = false
    },
  },
}
</script>
<style>
.v-form {
  position: relative;
}
.ql-toolbar.ql-snow {
  position: sticky;
  width: auto;
  top: 64px;
  z-index: 99999;
  background: #18181b;
  color: #d4d4d8 !important;
}
.v-field.v-field--appended {
  padding: 0;
}
</style>
