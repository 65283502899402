<template>
  <div>
    <div class="d-flex flex-column align-center justify-center" v-if="this.loading">
      <LoaderCircle />
    </div>
    <VRow v-else>
      <VCol xs="12" sm="8">
        <VCard class="bg-dark">
          <VCardTitle class="headline font-weight-bold white-text"> Merhaba, Ben Çağrı! 👋 </VCardTitle>

          <VCardText class="text-grey">
            Pitcher AG şirketinde Software Engineer olarak çalışıyorum.
            <b class="text-white">VUEJS, SQL, PHP, JQUERY, JAVASCRIPT, VUETIFY</b> gibi teknolojileri aktif olarak
            kullanıyorum. Bunların yanı sıra
            <b class="text-white">REACTJS, NODEJS, EXPRESSJS, MONGODB, SUPABASE</b> gibi teknolojileri kullanarak
            projeler üretmeye çalışıyorum. Yazılım dünyasına duyduğum ilgi ve tutkuyla geçen yıllar boyunca, birçok
            projede yer aldım ve sayısız problemi çözmek için kod yazdım. Bu süreçte edindiğim deneyimlerle birlikte,
            yazılım dünyasında sürekli öğrenmeye ve gelişmeye olan bağlılığımı sürdürüyorum.
          </VCardText>

          <VCardTitle class="headline font-weight-bold white-text">
            Bana ulaşabileceğiniz sosyal medya linkleri 📱
          </VCardTitle>

          <VCardTitle class="d-flex align-center">
            <VBtn
              @click="openLink(icon.link)"
              v-for="icon in icons"
              :key="icon.icon"
              class="me-3 d-flex bg-white"
              :icon="icon.icon"
              size="small"
            >
            </VBtn>
          </VCardTitle>

          <VCardText>
            <VDivider></VDivider>
          </VCardText>

          <VCardTitle class="headline font-weight-bold white-text">Blog ve Paylaşım İsteği 📝</VCardTitle>
          <VCardText class="text-grey">
            Bu tutkuyu başkalarıyla paylaşmak ve yazılım topluluğuna katkıda bulunmak amacıyla bu blogu oluşturdum.
            Blogumda, yazılım geliştirme süreçleri, teknoloji trendleri, problemlere pratik çözümler, ve daha birçok
            konuda paylaşımlar yapmayı planlıyorum. Sizlerle deneyimlerimi ve öğrendiklerimi paylaşarak, birlikte
            büyümemizi umuyorum.
          </VCardText>

          <VCardText>
            <VDivider></VDivider>
          </VCardText>

          <VCardTitle class="headline font-weight-bold white-text">Neler Bulabileceksiniz? 🌐</VCardTitle>
          <VCardText>
            <VList class="bg-dark text-grey">
              <VListItem>Yazılım Geliştirme Süreçleri</VListItem>
              <VListItem>Yeni Teknoloji Trendleri</VListItem>
              <VListItem>Problem Çözme Yaklaşımları</VListItem>
              <VListItem>Deneyimlerim ve Hatalardan Öğrendiklerim</VListItem>
              <VListItem>İlham Verici Proje Örnekleri</VListItem>
            </VList>
          </VCardText>

          <VCardText>
            <VDivider></VDivider>
          </VCardText>

          <VCardTitle class="headline font-weight-bold white-text">İletişim 🤝</VCardTitle>
          <VCardText class="text-grey">
            Sorularınızı, önerilerinizi veya sadece merhaba demek isterseniz benimle iletişime geçmekten çekinmeyin.
            Ayrıca, blogumdaki güncellemelerden haberdar olmak için sosyal medya hesaplarımı takip edebilirsiniz.
          </VCardText>

          <VCardTitle>
            <VBtn class="orange white-text" @click="navigateToContactPage"
              >MAIL ME <VIcon class="ms-3">mdi-email</VIcon></VBtn
            >
          </VCardTitle>
        </VCard>
      </VCol>
      <VCol class="col-12 col-md-6">
        <VRow>
          <VCardText class="grey-text py-0">
            <VCardTitle class="headline font-weight-bold pa-2 d-flex align-center white-text"> Techs </VCardTitle>
            <a class="ms-3" href="https://vuejs.org/" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/vuejs/vuejs-original-wordmark.svg"
                alt="vuejs"
                width="40"
                height="40"
              />
            </a>
            <a class="ms-3" href="https://reactjs.org/" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg"
                alt="react"
                width="40"
                height="40"
              />
            </a>
            <a
              class="ms-3"
              href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg"
                alt="javascript"
                width="40"
                height="40"
              />
            </a>
            <a class="ms-3" href="https://nodejs.org/" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/nodejs/nodejs.png"
                alt="nodejs"
                width="40"
                height="40"
              />
            </a>
            <a class="ms-3" href="https://www.php.net" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/devicons/devicon/master/icons/php/php-original.svg"
                alt="php"
                width="40"
                height="40"
              />
            </a>
            <a class="ms-3" target="_blank" rel="noreferrer">
              <img
                src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/sql/sql.png"
                alt="sql"
                width="40"
                height="40"
              />
            </a>
            <a class="ms-3" href="https://git-scm.com/" target="_blank" rel="noreferrer">
              <img src="https://www.vectorlogo.zone/logos/git-scm/git-scm-icon.svg" alt="git" width="40" height="40" />
            </a>
            <a class="ms-3" href="https://jestjs.io" target="_blank" rel="noreferrer">
              <img
                src="https://www.vectorlogo.zone/logos/jestjsio/jestjsio-icon.svg"
                alt="jest"
                width="40"
                height="40"
              />
            </a>
            <a class="ms-3" href="https://vuetifyjs.com/en/" target="_blank" rel="noreferrer">
              <img src="https://bestofjs.org/logos/vuetify.svg" alt="vuetify" width="40" height="40" />
            </a>
          </VCardText>
        </VRow>
        <VRow>
          <VCardText class="grey-text py-0">
            <VCardTitle
              @click="openLink('https://github.com/ecsabanci')"
              class="headline cursor-pointer font-weight-bold pa-2 d-flex align-center white-text"
            >
              Github Repos
              <VIcon class="ms-2" size="x-small">mdi-github</VIcon>
            </VCardTitle>

            <VTimeline density="compact" align="start">
              <VTimelineItem
                @click="openLink(detail.html_url)"
                v-for="detail in details"
                :key="detail.id"
                :dot-color="getRandomColor()"
                size="x-small"
              >
                <div class="mb-4">
                  <div class="font-weight-normal">
                    <strong>{{ detail.name }}</strong> <span v-if="detail.language">@{{ detail.language }}</span>
                  </div>
                  <div>{{ getConvertedDate(detail.pushed_at) }}</div>
                </div>
              </VTimelineItem>
            </VTimeline>
          </VCardText>
        </VRow>
      </VCol>
    </VRow>
  </div>
</template>
<script>
import axios from 'axios'
import LoaderCircle from '@/components/LoaderCircle.vue'
import { convertDate } from '@/utils/convertDate'

export default {
  name: 'MyDetails',
  components: { LoaderCircle },
  created() {
    this.fetchMyDetails()
  },
  data() {
    return {
      loading: true,
      details: [],
      color: 'indigo',
      icons: [
        { icon: 'mdi-twitter', link: 'https://twitter.com/ecsabanci' },
        { icon: 'mdi-linkedin', link: 'https://linkedin.com/in/ecsabanci' },
        { icon: 'mdi-instagram', link: 'https://www.instagram.com/ecsabanci' },
      ],
    }
  },
  methods: {
    fetchMyDetails() {
      axios
        .get('https://api.github.com/users/ecsabanci/repos')
        .then((response) => {
          this.details = response.data
          this.details.sort((a, b) => new Date(b.pushed_at) - new Date(a.pushed_at))
          this.loading = false
        })
        .catch((error) => {
          console.error(error)
          // Handle the error, e.g., show an error message
        })
    },
    getRandomColor() {
      const randomColor = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )})`
      return randomColor
    },
    openLink(link) {
      window.open(link, '_blank')
    },
    navigateToContactPage() {
      window.location.href = 'mailto:ecsabancii@gmail.com'
    },
    getConvertedDate(date) {
      return convertDate(date)
    },
  },
}
</script>
<style>
.v-timeline-item__body {
  cursor: pointer;
}

.v-timeline-divider__before,
.v-timeline-divider__after {
  background: #d4d4d8;
}

.v-timeline {
  height: 75vh !important;
  overflow: scroll !important;
}

.v-timeline::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
