<template>
  <div v-if="loaderType === 'articlecard'">
    <v-skeleton-loader color="#18181b" elevation="0" type="paragraph, chip"></v-skeleton-loader>
    <v-skeleton-loader color="#18181b" elevation="0" type="list-item"></v-skeleton-loader>
    <v-skeleton-loader class="my-8" color="#18181b" elevation="5" type="divider"></v-skeleton-loader>
  </div>
  <div class="bookmark-skeleton-loader" v-if="loaderType === 'bookmark'">
    <v-skeleton-loader color="#18181b" elevation="0" type="chip"></v-skeleton-loader>
    <v-skeleton-loader color="#18181b" elevation="0" type="sentences"></v-skeleton-loader>
    <v-skeleton-loader class="d-flex justify-end" color="#18181b" elevation="0" type="chip"></v-skeleton-loader>
    <v-skeleton-loader class="my-8" color="#18181b" elevation="5" type="divider"></v-skeleton-loader>
  </div>
  <div v-if="loaderType === 'article'">
    <v-skeleton-loader color="#18181b" elevation="0" type="text"></v-skeleton-loader>
    <v-skeleton-loader class="d-flex justify-space-between" color="#18181b" elevation="0" type="subtitle, chip"></v-skeleton-loader>
    <!-- <v-skeleton-loader color="#18181b" elevation="0" type="list-item-three-line"></v-skeleton-loader> -->
    <v-skeleton-loader color="#18181b" elevation="0" type="paragraph"></v-skeleton-loader>
    <v-skeleton-loader color="#18181b" elevation="0" type="text"></v-skeleton-loader>
    <v-skeleton-loader color="#18181b" elevation="0" type="paragraph"></v-skeleton-loader>
    <v-skeleton-loader color="#18181b" elevation="0" type="text"></v-skeleton-loader>
    <v-skeleton-loader color="#18181b" elevation="0" type="paragraph"></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  props: {
    loaderType: {
      type: String, // Assuming article is an object
      required: true, // You can change this based on your requirements
    },
  },
}
</script>
<style>
.bookmark-skeleton-loader .v-skeleton-loader__chip {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

</style>
