<template>
  <div>
    <VRow class="justify-center">
      <VCol xs="12" sm="6">
        <div
          class="bg-dark"
          target="_blank"
          rel="noopener"
          v-for="(bookmark, index) in paginatedArticles"
          :key="bookmark.id"
        >
          <div v-if="!this.loading">
            <div class="d-flex flex-column align-start ga-3">
              <div>
                <small class="d-flex align-center grey-text opacity-25 mb-4" v-if="bookmark.created_at">
                  <VIcon class="me-2 grey-text">mdi-calendar</VIcon>
                  {{ getConvertedDate(bookmark.created_at) }}
                </small>
                <VCardTitle
                  @click="this.openLink(bookmark.href)"
                  class="text-subtitle-1 pa-0 white-text bg-dark text-decoration-underline cursor-pointer"
                >
                  {{ bookmark.title }}
                </VCardTitle>
                <VCardText class="px-0 grey-text mt-4">
                  {{ bookmark.summary }}
                </VCardText>
              </div>
              <VBtn @click="handleLikeCount(bookmark.id, index)" class="d-flex ml-auto" color="#fbcfe81a">
                <VIcon color="pink" class="me-1 opacity-75">mdi-heart</VIcon>
                <span class="text-pink">{{ bookmark.like_count }}</span>
              </VBtn>
            </div>
            <VDivider color="grey" class="w-100 my-10" />
          </div>
          <LoaderCircle loaderType="bookmark" class="my-10" v-else />
        </div>

        <VPagination class="text-grey mt-4" :length="totalPages" v-model="currentPage"></VPagination>
      </VCol>
    </VRow>
  </div>
</template>
<script>
import { supabase } from '@/lib/supabaseClient'
import LoaderCircle from '@/components/LoaderCircle.vue'
import { convertDate } from '@/utils/convertDate'

export default {
  name: 'BookmarksView',
  components: { LoaderCircle },
  mixins: [],
  props: {},
  data() {
    return {
      bookmarks: [],
      loading: true,
      itemsPerPage: 3,
      currentPage: 1,
      numberOfPages: 0,
    }
  },
  created() {
    this.fetchBookmarks()
  },
  methods: {
    async fetchBookmarks() {
      const { data } = await supabase.from('bookmarks').select()
      this.bookmarks = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      this.loading = false
    },
    async handleLikeCount(id, index) {
      // Increment the like count locally
      this.bookmarks[index].like_count += 1

      // Update the like count in the database
      const { data, error } = await supabase
        .from('bookmarks')
        .update({ like_count: this.bookmarks[index].like_count })
        .eq('id', id)

      if (error) {
        console.error('Error updating clap count:', error.message)
        // If there's an error, you might want to revert the local update
        this.bookmarks[index].like_count -= 1
      } else {
        console.log('Clap count updated successfully:', data)
      }
    },
    openLink(link) {
      window.open(link, '_blank')
    },
    getConvertedDate(date) {
      return convertDate(date)
    },
  },
  watch: {
    currentPage(newPage) {
      this.currentPage = newPage
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.bookmarks.length / this.itemsPerPage)
    },
    paginatedArticles() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage
      return this.bookmarks.slice(startIndex, endIndex)
    },
  },
}
</script>
<style lang="" scoped></style>
