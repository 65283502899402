<template>
  <div>
    <VAppBar>
      <VContainer>
        <VRow dense class="justify-center">
          <VCol class="d-flex" xs="12" sm="6">
            <RouterLink to="/ecsabanci" class="text-decoration-none me-5"> Hakkımda </RouterLink>

            <RouterLink :to="item.route" class="text-decoration-none me-5" v-for="item in items" :key="item.title" link>
              <VListItemContent class="d-flex">
                <VListItemTitle>{{ item.title }}</VListItemTitle>
              </VListItemContent>
            </RouterLink>
          </VCol>
        </VRow>
      </VContainer>
      <!-- <RouterLink to="/ecsabanci" class="text-decoration-none me-5"> Hakkımda </RouterLink>

      <RouterLink :to="item.route" class="text-decoration-none me-5" v-for="item in items" :key="item.title" link>
        <VListItemContent class="d-flex">
          <VListItemTitle>{{ item.title }}</VListItemTitle>
          <VBadge
            v-if="item.countable && item.count > 0"
            :content="item.count"
            offset-y="5"
            max="4"
            floating
            color="#6fcfff"
          >
          </VBadge>
        </VListItemContent>
      </RouterLink> -->
    </VAppBar>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {}
  },
  computed: {
    items() {
      const baseItems = [
        { title: 'Yazılar', route: '/articles', countable: true, count: this.recentArticlesCount },
        { title: 'Bookmarks', route: '/bookmarks', countable: true, count: this.recentBookmarksCount },
      ]

      // Check if the admin feature is enabled
      if (process.env.VUE_APP_ADMIN === 'true') {
        // If enabled, include the "Yeni Yazı" item
        baseItems.push({ title: 'Yeni Yazı', route: '/addarticle' }, { title: 'Link Ekle', route: '/addbookmark' })
      }

      return baseItems
    },
  },
}
</script>

<style>
/* Add styles specific to the header component */
.v-application {
  height: 94px;
}
.v-toolbar__content,
.v-navigation-drawer__content {
  color: #d4d4d8;
  background: #18181b;
  box-shadow: unset;
  border: unset;
}
.v-list-item__content {
  display: flex;
  align-items: center;
}

.v-toolbar__content {
  justify-content: center;
  height: 94px !important;
}

.v-navigation-drawer {
  border: unset;
  z-index: 999999 !important;
  /* 31.223.44.97/32  */
  /* 0.0.0.0/0 */
}
</style>
