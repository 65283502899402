<template>
  <div>
    <VRow class="justify-center">
      <VCol cols="12" sm="6" v-if="isAdmin">
        <VForm class="border-0" validate-on="submit lazy" @submit.prevent="submitBookmark">
          <VTextField
            class="mb-5"
            v-model="bookmark.title"
            label="Başlık"
            :rules="rules"
            hide-details="auto"
          ></VTextField>
          <VTextField class="mb-5" v-model="bookmark.href" label="Link" :rules="rules" hide-details="auto"></VTextField>
          <VTextarea
            class="mb-5"
            v-model="bookmark.summary"
            label="Özet"
            :rules="rules"
            hide-details="auto"
          ></VTextarea>
          <VBtn :loading="loading" type="submit" block class="mt-2 orange rounded-0" text="GÖNDER" />
        </VForm>
      </VCol>
    </VRow>
  </div>
</template>
<script>
import { supabase } from '@/lib/supabaseClient'

export default {
  name: 'AddBookmarkView',
  data() {
    return {
      rules: [(value) => !!value || 'Required.', (value) => (value && value.length >= 5) || 'Min 5 characters'],
      bookmark: {
        title: '',
        href: '',
        summary: '',
      },
    }
  },
  methods: {
    async submitBookmark() {
      if (!this.bookmark.title && !this.bookmark.href && !this.bookmark.summary) {
        this.$toast.open({
          message: 'Lütfen göndermeden önce bookmark detaylarını girin!',
          type: 'error',
        })
        return // Stop further execution if validation fails
      }

      // Add or update article in the database
      const { error } = await supabase.from('bookmarks').insert(this.bookmark)

      // Show toast based on the result
      this.$toast.open({
        message: error ? 'Hata oluştu. Lütfen tekrar deneyin!' : 'Başarıyla Eklendi!',
        type: error ? 'error' : 'success',
      })

      if (!error) {
        this.clearFormFields()
      }
    },
    clearFormFields() {
      this.bookmark = {
        title: '',
        href: '',
        summary: '',
      }
    },
  },
  computed: {
    isAdmin() {
      return process.env.VUE_APP_ADMIN === 'true'
    },
  },
}
</script>
<style lang="" scoped></style>
