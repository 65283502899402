<template>
  <div class="body">
    <AppHeader />
    <VContainer class="app-container">
      <router-view />
      <!-- This will render the content of each page -->
    </VContainer>
    <AppFooter class="footer" />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue' // Adjust the path accordingly
import AppFooter from './AppFooter.vue'

export default {
  components: {
    AppHeader,
    AppFooter,
  },
}
</script>

<style>
.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-container {
  flex: 1;
  margin-top: 94px;
  margin-bottom: 94px;
}

.footer {
  margin-top: auto; /* Push the footer to the bottom */
}
</style>
