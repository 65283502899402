<template>
  <div class="articleView">
    <VRow class="justify-center" dense v-if="this.loading">
      <VCol xs="12" sm="6">
        <LoaderCircle loaderType="article" />
      </VCol>
    </VRow>
    <div v-else>
      <VRow class="justify-center" dense>
        <VCol xs="12" sm="6" class="d-flex align-center justify-space-between my-8">
          <small class="d-flex align-center grey-text opacity-50" v-if="article.date">
            <VIcon class="me-2">mdi-calendar</VIcon>
            {{ getConvertedDate(article.date) }}
            <span class="mx-2">·</span>
            {{ articleViewCount }} GÖRÜNTÜLEME
            <span class="mx-2">·</span>
            {{ calculateReadingTime(article.content) }} MIN READ
          </small>
        </VCol>
      </VRow>
      <VRow class="justify-center" dense>
        <VCol xs="12" sm="6">
          <div class="d-flex align-start justify-space-between mb-10">
            <h2 class="text-white">{{ article.title }}</h2>
            <VBtn @click="handleClap" class="clap" color="#fbcfe81a">
              <VTooltip activator="parent" location="top" v-if="!clapped">Beğen</VTooltip>
              <VTooltip activator="parent" location="top" v-else>Vazgeç</VTooltip>

              <VIcon class="me-3" color="pink" v-if="!clapped">mdi-heart</VIcon>
              <VIcon class="me-3" color="pink" v-else>mdi-heart</VIcon>
              <span class="text-pink">{{ clap }}</span>
            </VBtn>
          </div>
          <!-- <div v-if="article.image" class="image-container rounded-lg mb-8">
          <img class="w-100" :src="article.image" />
        </div> -->
          <div class="grey-text" v-html="article.content"></div>
        </VCol>
        <VDivider class="my-16" color="grey"></VDivider>
        <VCol xs="12" sm="6" v-if="!didCommented">
          <VCardTitle class="headline font-weight-bold pa-0 mb-4 grey-text"> Yorum Yap 💬</VCardTitle>
          <VForm class="border-0" validate-on="submit lazy" @submit.prevent="submitComment">
            <VTextField
              class="mb-5"
              v-model="userComment.name"
              label="İsim"
              :rules="rules"
              hide-details="auto"
            ></VTextField>
            <VTextarea
              class="mb-5"
              v-model="userComment.comment_content"
              label="Yorum"
              :rules="rules"
              hide-details="auto"
            ></VTextarea>
            <VBtn :loading="loading" type="submit" block class="mt-2 orange rounded-0 white-text" text="GÖNDER" />
          </VForm>
        </VCol>
        <VCol xs="12" sm="6" class="mt-16 grey-text" v-if="allComments.length > 0">
          <VCard class="pa-3 mb-4 comment" v-for="comment in allComments" :key="comment.comment_id">
            <div class="d-flex align-center justify-space-between">
              <VCardTitle>👻 {{ comment.name }} </VCardTitle>
              <small class="d-flex align-center grey-text">
                {{ getConvertedDate(comment.date) }}
              </small>
              <VMenu v-if="isAdmin">
                <template v-slot:activator="{ props }">
                  <VIcon icon="mdi-dots-vertical" v-bind="props"></VIcon>
                </template>

                <VList class="bg-white">
                  <VListItem class="text-black">
                    <VListItemTitle class="cursor-pointer" @click="deleteComment(comment.comment_id)">
                      Sil
                    </VListItemTitle>
                  </VListItem>
                </VList>
              </VMenu>
            </div>
            <VCardText>
              {{ comment.comment_content }}
            </VCardText>
          </VCard>
        </VCol>
      </VRow>
    </div>
  </div>
</template>

<script>
import { supabase } from '@/lib/supabaseClient'
import LoaderCircle from '@/components/LoaderCircle.vue'
import { convertDate } from '@/utils/convertDate'
import { calculateReadingTime } from '@/utils/calculateReadingTime'

export default {
  components: { LoaderCircle },
  data() {
    return {
      article: [],
      loading: true,
      clap: 0,
      clapped: false,
      userComment: {
        comment_content: '',
        name: '',
      },
      allComments: [],
      rules: [(value) => !!value || 'Required.', (value) => (value && value.length >= 5) || 'Min 5 characters'],
      didCommented: false,
      articleViewCount: 0,
    }
  },
  created() {
    this.fetchArticle()
    this.fetchAllComments()
    this.didCommented = localStorage.getItem(`didCommentedOn-${this.$route.params.id}`) === 'true' ? true : false
  },
  methods: {
    async fetchArticle() {
      const { data } = await supabase.from('articles').select().eq('id', this.$route.params.id)
      this.article = data[0]
      this.clap = this.article.clapCount
      this.loading = false
      this.articleViewCount = this.article.viewCount

      this.updateArticleViewCount()
    },
    async handleClap() {
      this.clapped = !this.clapped
      this.clapped ? (this.clap += 1) : (this.clap -= 1)
      const { data, error } = await supabase.from('articles').update({ clapCount: this.clap }).eq('id', this.article.id)

      if (error) {
        console.error('Error updating clap count:', error.message)
      } else {
        console.log('Clap count updated successfully:', data)
      }
    },
    getConvertedDate(date) {
      return convertDate(date)
    },
    clearFormFields() {
      this.userComment = {
        name: '',
        comment: '',
      }
    },
    async fetchAllComments() {
      const { data } = await supabase.from('comments').select().eq('id', this.$route.params.id)
      this.allComments = data
    },
    async submitComment() {
      if (!this.userComment.name && !this.userComment.comment) {
        this.$toast.open({
          message: 'Lütfen göndermeden önce adınızı ve yorumunuzu yazın!',
          type: 'error',
        })
        return // Stop further execution if validation fails
      }

      this.userComment.date = new Date()
      this.userComment.id = Number(this.$route.params.id)
      // Add or update article in the database
      const { error } = await supabase.from('comments').insert(this.userComment)

      // Show toast based on the result
      this.$toast.open({
        message: error ? 'Hata oluştu. Lütfen tekrar deneyin!' : 'Başarıyla Eklendi!',
        type: error ? 'error' : 'success',
      })

      if (!error) {
        this.didCommented = true
        localStorage.setItem(`didCommentedOn-${this.$route.params.id}`, true)
        this.allComments = [...this.allComments, this.userComment]
        this.clearFormFields()
      }
    },
    async deleteComment(id) {
      const { data, error } = await supabase.from('comments').delete().match({ comment_id: id })

      if (error) {
        console.error('Error deleting comment:', error.message)
        this.$toast.open({
          message: 'Yorum silinemedi!',
          type: 'error',
        })
      } else {
        console.log('Yorum başarıyla silindi:', data)
        this.allComments = this.allComments.filter((comment) => comment.comment_id !== id)
        this.$toast.open({
          message: 'Yorum başarıyla silindi!',
          type: 'success',
        })
      }
    },
    async updateArticleViewCount() {
      this.articleViewCount += 1
      const { data, error } = await supabase
        .from('articles')
        .update({ viewCount: this.articleViewCount })
        .eq('id', this.article.id)

      if (error) {
        console.error('Error updating view count:', error.message)
      } else {
        console.log('view count updated successfully:', data)
      }
    },
    calculateReadingTime(articleText) {
      return calculateReadingTime(articleText)
    },
  },
  computed: {
    isAdmin() {
      return process.env.VUE_APP_ADMIN === 'true'
    },
  },
}
</script>

<style>
.image-container {
  height: 200px;
  overflow: hidden;
}

pre {
  background: #002855 !important;
  color: #ffff;
  overflow: auto;
}

code {
  padding: 0.1rem;
  background: #eb5e31 !important;
  color: #d4d4d8;
}

p span {
  color: #d4d4d8 !important;
}

.articleView img {
  width: 100%;
  height: auto;
}

.articleView p {
  word-wrap: break-word;
}
</style>
