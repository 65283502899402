import { createRouter, createWebHistory } from 'vue-router'
import ArticlesView from '@/views/ArticlesView.vue'
import ArticleView from '@/views/ArticleView.vue'
import AddArticleView from '@/views/AddArticleView.vue'
import MyDetailsView from '@/views/MyDetailsView.vue'
import BookmarksView from '@/views/BookmarksView.vue'
import AddBookmarkView from '@/views/AddBookmarkView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ArticlesView,
  },
  {
    path: '/articles',
    name: 'Articles',
    component: ArticlesView,
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: ArticleView,
  },
  {
    path: '/addarticle',
    name: 'AddArticle',
    component: AddArticleView,
  },
  {
    path: '/updatearticle/:id',
    name: 'UpdateArticle',
    component: AddArticleView,
  },
  {
    path: '/ecsabanci',
    name: 'MyDetails',
    component: MyDetailsView,
  },
  {
    path: '/bookmarks',
    name: 'Bookmarks',
    component: BookmarksView,
  },
  {
    path: '/addbookmark',
    name: 'AddBookmark',
    component: AddBookmarkView,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 }
  },
})

export default router
